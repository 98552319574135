import { Component, OnInit } from '@angular/core';
import { HomeServiceService } from '../home-service.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {formatDate} from '@angular/common';
import {Title} from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-actualizar-licencia',
  templateUrl: './actualizar-licencia.component.html',
  styleUrls: ['./actualizar-licencia.component.css']
})
export class ActualizarLicenciaComponent implements OnInit {

  public validez = '01/02/1990';
  public tipoCuenta = 'Basica';
  public paymentId = null;
  public payerId = null;

  public showSuccess = false;
  public showError = false;

  public tipoCuentaAenviar = " ";
  public cuponAplicado = false;
  public cuponVerificar = false;
  public textoCuponVerificar;

  public selecPlanes;
  public nutricion;


  public pack1: number =  25;
  public pack2: number = 69;
  public pack3: number = 125;
  public pack4: number = 25;
  public pack5: number = 69;
  public pack6: number = 125;
  public pack7: number = 39; 
  public pack8: number = 106; 
  public pack9: number = 199;
  public pack10: number = 5;
  public pack11: number = 99;
  public pack12: number = 99;
  public pack13: number = 99;

  constructor(private HomeService: HomeServiceService, public router: Router, private activatedRoute: ActivatedRoute, private titleService:Title,  private spinner: NgxSpinnerService) {
    this.titleService.setTitle("AtlasFitness::Suscripcion");
    this.activatedRoute.queryParams.subscribe(params => {
      this.payerId = params['PayerID'];
      this.paymentId = params['paymentId'];
      if(this.payerId != null && this.paymentId !=null){
        this.spinner.show();
        this.completePayment(this.payerId,this.paymentId);
       }

    });
  }

  ngOnInit() {
   this.spinner.hide();
    sessionStorage.setItem('cuponDescuento',null);

  this.HomeService.getDatosPrecios().subscribe((res: any) => {
    }); 

    const data = new FormData();
    data.append('user_id2',sessionStorage.getItem('user_id2'));
    this.HomeService.getDatosLicencia(data).subscribe((res: any) => {
      this.validez = res.fechaFinLicencia;
      this.tipoCuenta= res.tipoLicencia;
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
      this.spinner.hide();
    }
    );

  }


  makePayment(product_id, tipoCuentaAenviarForm,periodo){

    
    this.spinner.show();
    if(sessionStorage.getItem('cuponDescuento') != "null"){
     sessionStorage.removeItem('cuponDescuento');
    }

      sessionStorage.setItem('tipoSuscripcion',tipoCuentaAenviarForm);
      sessionStorage.setItem('tipoMensualidad',periodo);
      const data = new FormData();
      data.append("sum",product_id);


     this.HomeService.makePayment(data).subscribe((res: any) => {

        window.location.href = res.redirect_url;

     },error => {
       this.spinner.hide();
     });
  
  }


  public aplicarCupon(cuponDescuento){

    const data = new FormData();
    data.append("cupon",cuponDescuento);

    this.HomeService.validarCupon(data).subscribe((res: any) => {

      if(res.nombreCupon == 'NoExiste'){
       // alert("El cupon no existe");
        this.textoCuponVerificar = "El cupon no existe";
        this.cuponVerificar = true;
      }else{
        if(res.fechaInicio >= formatDate(new Date(), 'yyyy-MM-dd', 'en') ){
         // alert("Este cupon no esta activo aun");
          this.textoCuponVerificar = "Este cupon no esta activo aun";
          this.cuponVerificar = true;
        }else if(res.fechaFin <= formatDate(new Date(), 'yyyy-MM-dd', 'en')){
         // alert("Este cupon esta inactivo");
          this.textoCuponVerificar = "Este cupon esta inactivo";
          this.cuponVerificar = true;
          
        }else{
          //modificar los precios
          this.actualizarPreciosCuponOk(res.descuento);
          this.cuponAplicado = true;
          sessionStorage.setItem('cuponDescuento',res.descuento);
        }
      }
   },error => {
       this.spinner.hide();
     });


  
  }

  completePayment(paymentId, payerId) {
    const data = new FormData();
    data.append('user_id2',sessionStorage.getItem('user_id2'));
    data.append("paymentId",paymentId);
    data.append("PayerID",payerId);
    data.append("tipoSuscripcion",sessionStorage.getItem('tipoSuscripcion'));
    data.append("tipoMensualidad",sessionStorage.getItem('tipoMensualidad'));

  
   
    this.HomeService.completePyament(data).subscribe((res: any) => {
      if(res.status = 'success'){
       
        this.showSuccess = true;
        const data = new FormData();
        data.append('user_id2',sessionStorage.getItem('user_id2'));
        this.HomeService.getDatosLicencia(data).subscribe((res: any) => {
        
          this.validez = res.fechaFinLicencia;
          this.tipoCuenta= res.tipoLicencia;
        });
        sessionStorage.removeItem('tipoSuscripcion');
        sessionStorage.removeItem('tipoMensualidad');

      }else{
        this.showError = true;
        sessionStorage.removeItem('tipoSuscripcion');
        sessionStorage.removeItem('tipoMensualidad');
      }
      sessionStorage.setItem('tipoSuscripcion',""); 
      this.spinner.hide();
   },error => {
    this.spinner.hide();
  });
  }

  public actualizarPreciosCuponOk(descuento){

   descuento = parseFloat(descuento);

    var resto1: number = this.pack1*descuento;
    var resto2: number = this.pack2*descuento;
    var resto3: number = this.pack3*descuento;
    var resto4: number = this.pack4*descuento;
    var resto5: number = this.pack5*descuento;
    var resto6: number = this.pack6*descuento;
    var resto7: number = this.pack7*descuento;
    var resto8: number = this.pack8*descuento;
    var resto9: number = this.pack9*descuento;
    var resto10: number = this.pack10*descuento;
    var resto11: number = this.pack11*descuento;
    var resto12: number = this.pack12*descuento;
    var resto13: number = this.pack12*descuento;

    this.pack1 =  this.pack1-resto1;
    this.pack2 =  this.pack2-resto2;
    this.pack3 =  this.pack3-resto3;
    this.pack4 =  this.pack4-resto4;
    this.pack5 = this.pack5-resto5;
    this.pack6 =  this.pack6-resto6;
    this.pack7 = this.pack7-resto7;
    this.pack8 = this.pack8-resto8;
    this.pack9 = this.pack9 -resto9;
    this.pack10 = this.pack9 -resto10;
    this.pack11 = this.pack9 -resto11;
    this.pack12 = this.pack9 -resto12;
    this.pack13 = this.pack9 -resto13;
  

  }


}


